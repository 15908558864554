<template>
  <v-app fill-height>
    <Snackbar />
    <Loader />
    <NavBar />
    <v-main>
      <v-scroll-y-transition :hideOnLeave="true">
        <router-view :key="$route.fullPath" />
      </v-scroll-y-transition>
    </v-main>
    <Dialog ref="confirm" />
  </v-app>
</template>
<script>
export default {
  components: {
    NavBar: () => import(/* webpackChunkName: "NavBar" */ "@/components/layout/NavBar.vue"),
  },
  metaInfo() {
    return {
      title: process.env.VUE_APP_NAME,
      titleTemplate: `%s | ${process.env.VUE_APP_NAME}`,
    };
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open;
  },
};
</script>
